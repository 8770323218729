
import { defineComponent, provide } from 'vue'
import axios from '@/http'
import { IStorage } from '@/interfaces'
import WareHouseMap from '@/components/warehouse_map/index.vue'

export default defineComponent({
	components: { WareHouseMap },
	setup() {
		async function getStoragesByCellId(cellId: number): Promise<IStorage | undefined> {
			try {
				const result = await axios.get('/storage/get-storages-by-cell-id/' + cellId)
				return result.data
			} catch (e) {
				console.error('getStoragesByCellId', e)
			}
		}

		async function getStoragesByPhysicalCell(): Promise<IStorage | undefined> {
			try {
				const result = await axios.get('/storage/get-storages-by-physical-cell')
				return result.data
			} catch (e) {
				console.error('getStoragesByPhysicalCell', e)
			}
		}

		provide('getStoragesByCellId', getStoragesByCellId)
		provide('getStoragesByPhysicalCell', getStoragesByPhysicalCell)
	}
})
