
import { defineComponent, onBeforeMount, ref } from 'vue'
import axios from '@/http'
import { IStorage, ICell } from '@/interfaces'
import CellCard from './CellCard.vue'
interface ICellStorage extends ICell {
	storages: IStorage[]
}
export default defineComponent({
	components: { CellCard },
	setup() {
		const cells = ref<Array<ICellStorage>>()
		async function getStoragesByPhysicalCell(): Promise<Array<ICellStorage> | undefined> {
			try {
				const result = await axios.get('/storage/get-storages-by-physical-cell')
				return result.data
			} catch (e) {
				console.error('getStoragesByPhysicalCell', e)
			}
		}
		onBeforeMount(async () => {
			cells.value = await getStoragesByPhysicalCell()
		})
		return {
			cells
		}
	}
})
