
import { defineComponent, ref } from 'vue'
import AddCell from './AddCell.vue'
import HandleJob from './HandleJob.vue'
import PhysicalStorages from './PhysicalStorages.vue'
export default defineComponent({
	components: {
		AddCell,
		HandleJob,
		PhysicalStorages
	},
	setup() {
		const selectedTab = ref('0')
		return {
			selectedTab
		}
	}
})
