
import { defineComponent, PropType, computed } from 'vue'
import { IStorage } from '@/interfaces'
export default defineComponent({
	props: {
		id: {
			type: [Number, String]
		},
		title: {
			type: String
		},
		variations: {
			type: Array as PropType<IStorage[]>
		}
	},
	setup(props) {
		const variationHeight = computed(() => {
			const { variations } = props
			if (Array.isArray(variations)) {
				const result: {
					[key: string]: any
				} = {}
				for (const variation of variations) {
					const { holded, quantity, variationId } = variation
					const sum = holded + quantity
					let height = 20
					if (sum > 1) {
						height = 20 + (sum - 1) * 10
					}
					result[variationId] = height
				}
				return result
			}
			return {}
		})
		return {
			variationHeight
		}
	}
})
