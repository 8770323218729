
import { defineComponent, onBeforeMount, ref, inject } from 'vue'
import CellCard from './CellCard.vue'
export default defineComponent({
	components: { CellCard },
	setup() {
		const getStoragesByCellId = inject<((cellId: number) => void) | undefined>('getStoragesByCellId', undefined)
		const collectionVariations = ref()
		const sendingVariations = ref()
		onBeforeMount(async () => {
			if (getStoragesByCellId) {
				const [collection, sending] = await Promise.all([getStoragesByCellId(3), getStoragesByCellId(4)])
				collectionVariations.value = collection
				sendingVariations.value = sending
			}
		})
		return {
			collectionVariations,
			sendingVariations
		}
	}
})
