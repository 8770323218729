import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "handle-job" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CellCard = _resolveComponent("CellCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.collectionVariations)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CellCard, {
            title: "Сборка",
            variations: _ctx.collectionVariations,
            id: 3
          }, null, 8, ["variations"]),
          _createVNode(_component_CellCard, {
            title: "Отправка",
            variations: _ctx.sendingVariations,
            id: 4
          }, null, 8, ["variations"])
        ]))
      : _createCommentVNode("", true)
  ]))
}