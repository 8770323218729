import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cell-card" }
const _hoisted_2 = { class: "cell-card__header" }
const _hoisted_3 = { class: "cell-card__title" }
const _hoisted_4 = { class: "cell-card__body" }
const _hoisted_5 = { class: "cell-card__variation-count-container" }
const _hoisted_6 = { class: "cell-card__variation-count" }
const _hoisted_7 = { class: "cell-card__variation-count" }
const _hoisted_8 = {
  key: 1,
  class: "cell-card__variation_empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", null, "ID: " + _toDisplayString(_ctx.id), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (Array.isArray(_ctx.variations) && _ctx.variations.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.variations, (variation) => {
            return (_openBlock(), _createElementBlock("div", {
              key: variation.variationId,
              class: "cell-card__variation",
              style: _normalizeStyle({
						height: _ctx.variationHeight[variation.variationId] + 'px'
					})
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("b", null, _toDisplayString(variation.variationId), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, " Q-" + _toDisplayString(variation.quantity), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", null, " H-" + _toDisplayString(variation.holded), 1)
                ])
              ])
            ], 4))
          }), 128))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, "Ячейка пуста"))
    ])
  ]))
}