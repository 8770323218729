import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddCell = _resolveComponent("AddCell")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_PhysicalStorages = _resolveComponent("PhysicalStorages")!
  const _component_HandleJob = _resolveComponent("HandleJob")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.selectedTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event)),
      lazy: "",
      type: "card",
      class: "demo-tabs"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, { label: "Добавление" }, {
          default: _withCtx(() => [
            (_ctx.selectedTab === '0')
              ? (_openBlock(), _createBlock(_component_AddCell, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          lazy: "",
          label: "Адресное хранение"
        }, {
          default: _withCtx(() => [
            (_ctx.selectedTab === '1')
              ? (_openBlock(), _createBlock(_component_PhysicalStorages, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          lazy: "",
          label: "Обработка заказов"
        }, {
          default: _withCtx(() => [
            (_ctx.selectedTab === '2')
              ? (_openBlock(), _createBlock(_component_HandleJob, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}