
import { defineComponent, onBeforeMount, ref, inject } from 'vue'
import CellCard from './CellCard.vue'
export default defineComponent({
	components: { CellCard },
	setup() {
		const getStoragesByCellId = inject<((cellId: number) => void) | undefined>('getStoragesByCellId', undefined)
		const transferVariations = ref()
		const photozoneVariations = ref()
		onBeforeMount(async () => {
			if (getStoragesByCellId) {
				const [transfer, photozone] = await Promise.all([getStoragesByCellId(1), getStoragesByCellId(2)])
				transferVariations.value = transfer
				photozoneVariations.value = photozone
			}
		})
		return {
			transferVariations,
			photozoneVariations
		}
	}
})
