import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CellCard = _resolveComponent("CellCard")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_row, { gutter: 10 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cells, (cell) => {
            return (_openBlock(), _createBlock(_component_el_col, {
              xs: 24,
              md: 12,
              lg: 8,
              key: cell.name
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CellCard, {
                  title: cell.name,
                  variations: cell.storages,
                  id: cell.id
                }, null, 8, ["title", "variations", "id"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}